import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { ApiResponse } from 'sumax-erp-schematics';

import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

import { TbEntidadContacto } from '~models/maestros';
import { HttpClient } from '@angular/common/http';

interface EntidadContactoService {
	getSelectList(idEntidad: number, saveInMemory: boolean): Observable<NgSelectOption<TbEntidadContacto>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class TbEntidadContactoService implements EntidadContactoService {
	private readonly _url = `${environment.HOST_MAESTROS}/tbentidadcontactos`;
	private _dataSelect$?: Observable<NgSelectOption<TbEntidadContacto>[]>;

	$$ejecutivo = new BehaviorSubject<TbEntidadContacto | null>(null);

	constructor(private readonly _http: HttpClient) {}

	findAllByIdEntidadAndEstado(idEntidad: number): Observable<TbEntidadContacto[]> {
		return this._http.get<ApiResponse<TbEntidadContacto[]>>(`${this._url}/model-se-ro/findAllByIdEntidadAndEstado/${idEntidad}/${String(true)}`).pipe(map((res) => res.data));
	}

	findAllByIdList(ids: number[]): Observable<TbEntidadContacto[]> {
		return this._http.post<ApiResponse<TbEntidadContacto[]>>(`${this._url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	getSelectList(idEntidad: number | undefined | null, saveInMemory = true): Observable<NgSelectOption<TbEntidadContacto>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$ && idEntidad)
			this._dataSelect$ = this.findAllByIdEntidadAndEstado(idEntidad).pipe(
				map((data) =>
					data.map((item) => ({
						...item,
						value: item.idEntidadContacto,
						label: `${item.nombre ?? ''} ${item.apellido1 ?? ''} ${item.apellido2 ?? ''}`,
					}))
				),
				shareReplay(1)
			);
		else this._dataSelect$ = of([]);

		return this._dataSelect$;
	}

	getSelect(e: TbEntidadContacto | null | undefined): NgSelectOption<TbEntidadContacto> | null {
		return e
			? {
					...e,
					value: e.idEntidadContacto,
					label: `${e.nombre ?? ''} ${e.apellido1 ?? ''} ${e.apellido2 ?? ''}`,
			  }
			: null;
	}
}
